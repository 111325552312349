import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Chat from './Pages/Chat/Chat';
import { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from './context/DarkModeContext';
import './App.scss';
import SingleChat from './Pages/singleChat/SingleChat';
import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';
import { useSelector } from 'react-redux';
import MiniHome from './Pages/MiniHome/MiniHome';
import Layout from './components/Layout/Layout';

function App() {
  const [theme, setTheme] = useState(true);
  const { darkMode } = useContext(DarkModeContext);
  const currenTheme = localStorage.getItem('theme');
  const user = useSelector((state) => state.user.currentUser);
  useEffect(() => {
    const CheckCurrentTheme = () => {
      if (currenTheme) {
        if (currenTheme === 'dark') {
          setTheme(true);
        } else {
          setTheme(false);
        }
      } else {
        setTheme(darkMode);
      }
    };
    CheckCurrentTheme();
  }, [darkMode, currenTheme]);

  return (
    <div className={`Theme-${theme ? 'dark' : 'light'}`}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MiniHome />} />

          <Route path="/auth">
            <Route
              path="login"
              element={user ? <Navigate to="/chat" /> : <Login />}
            />
            <Route
              path="register"
              element={user ? <Navigate to="/chat" /> : <Register />}
            />
          </Route>

          {user && (
            <Route path="/chat" element={<Layout />}>
              <Route index element={<Chat />} />
              <Route path=":chatId" element={<SingleChat />} />
            </Route>
          )}
          <Route path="*" element={<MiniHome />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
