import React, { useEffect, useState } from 'react';
import './Login.scss';
import fullLogo from '../../assets/fulllogo_transparent.png';
import fullLogoDark from '../../assets/fulllogo_transparent_nobuffer.png';
import { Link } from 'react-router-dom';
import {
  Alert,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Redux/Auth';

const Login = () => {
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { error, isFetching, errorMessage } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const validator = () => {
      if (username && password) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validator();
  }, [username, password]);

  const handleLogin = async () => {
    await login(dispatch, {
      username,
      password,
    });
  };
  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter' && !disabled && !isFetching) {
        e.preventDefault();
        handleLogin();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [disabled, isFetching, username, password]);
  return (
    <div className="login-page">
      <div className="title-logo">
        <p>Cortexini</p>
        {/* <Link to="/">
        <img
          src={fullLogoDark}
          alt="logo"
          className="logo-dark"
        />
      </Link>
      <Link to="/">
        <img
          src={fullLogo}
          alt="logo"
          className="logo-light"
        />
      </Link> */}
        <p>Sign in to your account</p>
      </div>
      <div className="register-box">
        <div className="form">
          <div className="input-box">
            <TextField
              id="username"
              label="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                onChange={(e) => setPassword(e.target.value)}
                required
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          {error && (
            <Alert severity="error" style={{ marginTop: '15px' }}>
              {errorMessage}
            </Alert>
          )}
          <button
            className="next-register-bttn"
            disabled={disabled || isFetching}
            onClick={handleLogin}
          >
            Sign in
          </button>
          <div className="registerOrsign-div">
            <span>Don't have account yet?</span>
            <Link to="/register">Sign up</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
