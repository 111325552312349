import React from 'react';
import './deletepopup.scss';
import { userRquest } from '../../ApiRequests';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DeletePopup = ({
  setState,
  state,
  title,
  deletechatId,
  setnewChatCount,
}) => {
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const handleOutClose = (e) => {
    const target = e.target;
    if (target.classList[0] === 'delete-popup-main-wrapper') {
      setState(false);
    }
  };

  const handleDeleteChat = async () => {
    try {
      await userRquest.delete(`/userchats/${deletechatId}/${user._id}`);
      setState(false);
      navigate('/chat');
      setnewChatCount((prev) => prev + 1);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div
      className={`delete-popup-main-wrapper ${!state && 'hidden'}`}
      onClick={handleOutClose}
    >
      <div className="delete-conntainer">
        <p className="delete-title">Delete Chat ?</p>
        <div className="bottom-wrapper">
          <p className="msg-delete">
            This will delete <b>{title}</b>{' '}
          </p>
          <div className="bttn-wrapper">
            <button className="cancel" onClick={() => setState(false)}>
              Cancel
            </button>
            <button className="delete" onClick={handleDeleteChat}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
