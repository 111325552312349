import React, { useEffect, useState } from 'react';
import { BsLayoutSidebar } from 'react-icons/bs';
import { AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';
import { BiMessage } from 'react-icons/bi';
import { FiEdit3 } from 'react-icons/fi';
import './sideBar.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userRquest } from '../../ApiRequests';
import { useSelector } from 'react-redux';
import { Box, Skeleton } from '@mui/material';
import DeletePopup from '../deletepopup/Deletepopup';
import useMediaQuery from '@mui/material/useMediaQuery';

const SideBar = () => {
  const [inputVal, setInputVal] = useState('');
  const [chats, SetChats] = useState([]);
  const { chatId } = useParams();
  const [isFetching, setisFectching] = useState(false);
  const [newChatCount, setnewChatCount] = useState(0);
  const [editTarget, setEditTarget] = useState();
  const [chatTitle, setChatTitle] = useState('');
  const [deletechatId, setdeleteChatId] = useState('');
  const [deleteShow, setDeleteShow] = useState(false);
  const matches = useMediaQuery('(max-width:680px)');
  const [show, setShow] = useState(matches ? false : true);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const active = document.getElementById(chatId);

  const editChatTitle = async () => {
    try {
      await userRquest.put(`/userchats/${chatId}/${user._id}`, {
        title: inputVal,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (e) => {
    setEditTarget(e);
    const parent = e.target.parentNode.parentNode;
    const text = parent.childNodes[2];
    const input = parent.childNodes[1];
    input.value = text.innerText;
    if (input.classList[1] === 'active') {
      if (inputVal !== '') {
        text.innerText = inputVal;
        editChatTitle();
        setInputVal('');
      }
      text.classList.remove('hidden');
      input.classList.remove('active');
    } else {
      text.classList.add('hidden');
      input.classList.add('active');
    }
  };

  const handleNewChat = async () => {
    try {
      const newChat = await userRquest.post(`/userchats/${user._id}`);
      const ChatId = newChat.data._id;
      navigate(`/chat/${ChatId}`);
      setnewChatCount(newChatCount + 1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkParams = () => {
      if (chatId && active) {
        const allDiv = document.getElementsByClassName('chat-info');
        const edit = document.getElementsByClassName('edit-text');
        const title = document.getElementsByClassName('chat-title');

        for (let i = 0; i < allDiv.length; i++) {
          allDiv[i].classList.remove('active');
          edit[i].classList.remove('active');
          title[i].classList.remove('hidden');
        }
        active.classList.add('active');
      }
    };
    checkParams();
  }, [chatId, active]);

  const handleDelete = (e, id) => {
    const parent = e.target.parentNode.parentNode;
    const text = parent.childNodes[2];
    setChatTitle(text.innerText);
    setDeleteShow(true);
    setdeleteChatId(id);
  };

  useEffect(() => {
    const fetchAllChats = async () => {
      try {
        setisFectching(true);
        const chats = await userRquest.get(`userchats/${user._id}`);
        const AllChats = chats.data;
        SetChats(AllChats);
        setisFectching(false);
      } catch (err) {
        setisFectching(false);
      }
    };
    fetchAllChats();
  }, [newChatCount]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter' && editTarget && inputVal) {
        handleEdit(editTarget);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [editTarget, inputVal]);

  return (
    <div className={`right-nav-bar ${!show && 'hidden'}`}>
      <DeletePopup
        state={deleteShow}
        setState={setDeleteShow}
        title={chatTitle}
        deletechatId={deletechatId}
        setnewChatCount={setnewChatCount}
      />
      <div
        className={`open-side-bar-bttn ${!show && 'active'}`}
        onClick={() => setShow(!show)}
      >
        <BsLayoutSidebar />
      </div>
      <div className="top-bar">
        <div className="box-icon" onClick={() => setShow(!show)}>
          <BsLayoutSidebar />
        </div>
        <div className="add-chat-box" onClick={handleNewChat}>
          <AiOutlinePlus />
          <p>New chat</p>
        </div>
      </div>
      <div className="Chat-history-container">
        {isFetching ? (
          <Box sx={{ width: '256px' }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : (
          chats.map(
            (chatsStats) =>
              chatsStats?.chats?.length > 0 && (
                <>
                  <h3>{chatsStats.period}</h3>
                  {chatsStats.chats.map((chat) => (
                    <Link
                      key={chat._id}
                      to={`/chat/${chat._id}`}
                      className="chat-info"
                      id={chat._id}
                    >
                      <BiMessage className="mssg-icon" />
                      <input
                        type="text"
                        className="edit-text"
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                      <p className="chat-title">{chat.title}</p>
                      <div className="bttn-div">
                        <button onClick={(e) => handleEdit(e)}>
                          <FiEdit3 style={{ pointerEvents: 'none' }} />
                        </button>
                        <button onClick={(e) => handleDelete(e, chat._id)}>
                          <AiOutlineDelete style={{ pointerEvents: 'none' }} />
                        </button>
                      </div>
                    </Link>
                  ))}
                </>
              )
          )
        )}
      </div>
    </div>
  );
};

export default SideBar;
