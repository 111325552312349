import { createSlice } from '@reduxjs/toolkit';

const userslice = createSlice({
  name: 'user',
  initialState: {
    messageTitle: null,
    chat_id: null,
    currentUser: null,
    isFetching: false,
    error: false,
    errorMessage: null,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
      state.errorMessage = null;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    setMessageTitle: (state, action) => {
      state.messageTitle = action.payload.title;
      state.chat_id = action.payload.chat_id;
    },
    resetMessage: (state) => {
      state.messageTitle = null;
      state.chat_id = null;
    },
  },
});
export const {
  loginStart,
  loginSuccess,
  loginFailure,
  setMessageTitle,
  resetMessage,
} = userslice.actions;
export default userslice.reducer;
