import axios from 'axios';
import Cookies from 'js-cookie';

const publicRequest = axios.create({
  baseURL: 'https://aiserver.menamahany.tech',
});

const userRquest = axios.create({
  baseURL: 'https://aiserver.menamahany.tech',
  withCredentials: true,
});

userRquest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // response status is 401
      Cookies.remove('Authorization');
      localStorage.clear();
      window.location.reload();
      window.location.href = '/';
    }
    throw error;
  }
);

export { publicRequest, userRquest };
