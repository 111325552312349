import React, { useEffect, useRef, useState } from 'react';
import './SingleChat.scss';
import { BiSolidSend } from 'react-icons/bi';
import UserInfo from '../../components/userInfo/UserInfo';
import { userRquest } from '../../ApiRequests';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import geminiIcon from '../../assets/google-gemini-icon.webp';
import { resetMessage } from '../../Redux/userSlice';
import Typewriter from 'typewriter-effect';

const SingleChat = () => {
  const [disabled, setDisabled] = useState(true);
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState('');
  const [messageCount, setmessageCount] = useState(0);
  const [animatedId, setAnimatedId] = useState(null);
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const [isFetching, setIsFetching] = useState(false);
  const { chatId } = useParams();
  const { currentUser, messageTitle, chat_id } = useSelector(
    (state) => state.user
  );
  const messagesContainer = useRef();
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Triggers only if current user created new chat so message will be sent automatically from previous screen
  useEffect(() => {
    const handleQueryMessage = async () => {
      setError({
        status: false,
        message: null,
      });
      try {
        setMessages([
          ...messages,
          {
            chat_id: chatId,
            content: messageTitle,
            isUser: true,
          },
        ]);
        setIsTyping(true);
        const newMessage = await userRquest.post(
          `/newmessage/${chatId}/${currentUser._id}`,
          {
            content: messageTitle,
          }
        );
        dispatch(resetMessage());
        setIsTyping(false);
        setMessages([...messages, ...newMessage.data]);
        setAnimatedId(newMessage.data[1]._id);
      } catch (err) {
        dispatch(resetMessage());
        setError({
          status: true,
          message: 'something went wrong',
        });
        setIsTyping(false);
      }
    };
    if (messageTitle && chat_id === chatId) {
      handleQueryMessage();
    }
  }, [messageTitle]);

  const autoResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
    const value = e.target.value;
    if (value.length > 0) {
      setDisabled(false);
      setContent(value);
    } else {
      setDisabled(true);
    }
  };

  const scrollToBottom = () => {
    messagesContainer.current?.scroll({
      top: messagesContainer.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // handles send new message to AI and save it to DB
  const SendNewMessage = async () => {
    setError({
      status: false,
      message: null,
    });
    const input = document.getElementById('message-input');
    try {
      setMessages([
        ...messages,
        {
          chat_id: chatId,
          content,
          isUser: true,
        },
      ]);
      input.value = '';
      setIsTyping(true);
      const newMessage = await userRquest.post(
        `/newmessage/${chatId}/${currentUser._id}`,
        {
          content,
        }
      );
      setIsTyping(false);
      setMessages([...messages, ...newMessage.data]);
      setAnimatedId(newMessage.data[1]._id);
      setmessageCount(messageCount + 1);
      setDisabled(true);
      const textArea = document.getElementById('message-input');
      textArea.style.height = 'auto';
    } catch (err) {
      setError({
        status: true,
        message:
          typeof err?.response?.data === 'string'
            ? err?.response?.data
            : 'something went wrong',
      });
      setIsTyping(false);
    }
  };

  // fetches current chat messages
  useEffect(() => {
    const fetchAllMessages = async () => {
      try {
        setIsFetching(true);
        setIsTyping(false);
        if (messageTitle) {
          dispatch(resetMessage());
        }
        const messages = await userRquest.get(
          `/allmessages/${chatId}/${currentUser._id}`
        );
        setMessages(messages.data);
        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
        setError({
          status: true,
          message: 'unable to load chat',
        });
        setTimeout(() => {
          navigate('/chat');
        }, [3000]);
      }
    };
    if (chatId !== undefined && currentUser && chat_id !== chatId) {
      fetchAllMessages();
    }
  }, [chatId]);

  // removes unrelatted messages in case user switched chat and in progress AI awaiting response
  useEffect(() => {
    const length = messages.length;
    if (length > 0) {
      const foundUnrelated = messages.find((mssg) => mssg.chat_id !== chatId);
      if (foundUnrelated) {
        const slicedMessages = messages.slice(0, -2);
        setMessages(slicedMessages);
      }
    }
  }, [messageCount]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter' && !disabled && !e.shiftKey) {
        e.preventDefault();
        SendNewMessage(content);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [disabled, content]);

  return (
    <div className="Chat-page">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error.status}
        autoHideDuration={6000}
        onClose={() =>
          setError({
            status: false,
            message: null,
          })
        }
      >
        <Alert
          onClose={() =>
            setError({
              status: false,
              message: null,
            })
          }
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error.message}
        </Alert>
      </Snackbar>
      <div className="Chat-main-wrapper">
        <UserInfo />
        <div className="Ai-chat-wrapper">
          <div className="absolute-input-div">
            <div className="absolute-wrapper">
              <div className="inout-div">
                <textarea
                  id="message-input"
                  onChange={(e) => autoResize(e)}
                  placeholder="Send a message"
                />
                <button
                  className="send-icon"
                  disabled={disabled || isTyping}
                  onClick={SendNewMessage}
                >
                  <BiSolidSend />
                </button>
              </div>

              <p className="small-footer-text" style={{ fontSize: '12px' }}>
                Free Research Preview. Cortexini 1.0 Pro may produce inaccurate
                information about people, places, or facts. Built on top of
                Gemini by google.
              </p>
            </div>
          </div>
          <div className="top-nav-chat-chat">
            <span>Gemini 1.0 Pro</span>
          </div>
          <div className="scrollable-chat-wrapper" ref={messagesContainer}>
            {isFetching ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: 'calc(100%  - 200px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              messages.map((message) => (
                <div
                  key={message._id}
                  className={`chat-box ${message.isUser ? 'user' : 'ai'}`}
                >
                  <div className="chat-main-text-wrapper">
                    {message.isUser ? (
                      <div className="user-logo">{`${currentUser?.fName[0]}${currentUser?.lName[0]}`}</div>
                    ) : (
                      <img src={geminiIcon} alt="aiLogo" className="aiLogo" />
                    )}
                    <span>
                      {animatedId === message._id ? (
                        <Typewriter
                          onInit={(typewriter) => {
                            typewriter.typeString(message.content).start();
                          }}
                          options={{
                            cursor: null,
                            delay: 0,
                          }}
                        />
                      ) : (
                        message?.content
                      )}
                    </span>
                  </div>
                </div>
              ))
            )}
            {isTyping && (
              <div className="chat-box ai">
                <div className="chat-main-text-wrapper">
                  <img src={geminiIcon} alt="aiLogo" className="aiLogo" />

                  <span className="loader"></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleChat;
