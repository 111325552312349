import React, { useEffect, useRef, useState } from 'react';
import userImg from '../../assets/images.jpg';
import { BsThreeDots, BsChatLeftText } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { BiLogIn } from 'react-icons/bi';
import './userInfo.scss';
import { persistor } from '../../Redux/store';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Button } from '@mui/material';
import Settings from '../settingsPopUp/Settings';

const UserInfo = () => {
  const userInfo = useRef(null);
  const [showSetting, setShowSettings] = useState(false);
  const [hidden, setHidden] = useState(true);
  const user = useSelector((state) => state.user.currentUser);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSettings(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(userInfo);
  const showSettingPopup = () => {
    setHidden(false);
    setShowSettings(false);
  };

  const handleLogout = () => {
    persistor.purge();
    Cookies.remove('Authorization');
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="user-info-wrapper" ref={userInfo}>
      <Settings state={hidden} setState={setHidden} />

      <div className="user-info" onClick={() => setShowSettings(!showSetting)}>
        <img src={userImg} alt="userImg" />
        <p>{user.email}</p>
        <BsThreeDots className="user-more-details" />
      </div>
      <div className={`profile-settings ${showSetting && 'active'}`}>
        <Button className="setting-bttn">
          <BsChatLeftText style={{ userSelect: 'none' }} />
          Custom instructions
        </Button>
        <Button className="setting-bttn" onClick={showSettingPopup}>
          <FiSettings style={{ userSelect: 'none' }} />
          Settings
        </Button>
        <Button className="setting-bttn" disabled style={{ opacity: '0.4' }}>
          <FiSettings style={{ userSelect: 'none' }} />
          Subscriptions (soon)
        </Button>
        <button className="logout-bttn" onClick={handleLogout}>
          <BiLogIn style={{ userSelect: 'none' }} />
          Log out
        </button>
      </div>
    </div>
  );
};

export default UserInfo;
