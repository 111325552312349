import React from 'react';
import SideBar from '../sideBar/sideBar';
import { Outlet } from 'react-router-dom'; // Import Outlet from react-router-dom

const Layout = () => {
  return (
    <div>
      <SideBar />
      <Outlet />
    </div>
  );
};

export default Layout;
