import React, { useEffect, useState } from 'react';
import './chat.scss';
import { BsStars } from 'react-icons/bs';
import { SlEnergy } from 'react-icons/sl';
import { BiSolidSend } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import UserInfo from '../../components/userInfo/UserInfo';
// import mainLogo from '../../assets/icononly_transparent_nobuffer.png';
import { userRquest } from '../../ApiRequests';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { setMessageTitle } from '../../Redux/userSlice';

const Chat = () => {
  const [disabled, setDisabled] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  const handleNewChat = async (message) => {
    try {
      setError({
        status: false,
        message: null,
      });
      const newChat = await userRquest.post(`/userchats/${user._id}`, {
        title: message,
      });
      const response = newChat.data;
      dispatch(
        setMessageTitle({
          title: message,
          chat_id: response._id,
        })
      );
      const ChatId = newChat.data._id;
      navigate(`/chat/${ChatId}`);
    } catch (err) {
      setError({
        status: true,
        message: 'something went wrong',
      });
    }
  };

  const autoResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
    if (e.target.value) {
      setDisabled(false);
      setNewMessage(e.target.value);
    } else {
      setDisabled(true);
    }
  };
  const toggle = () => {
    const activeButton = document.getElementsByClassName('toggle-box');
    if (activeButton[0].classList[1] === 'active') {
      activeButton[0].classList.remove('active');
      activeButton[1].classList.add('active');
    } else {
      activeButton[1].classList.remove('active');
      activeButton[0].classList.add('active');
    }
  };
  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter' && !disabled && !e.shiftKey) {
        e.preventDefault();
        handleNewChat(newMessage);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [disabled, newMessage]);
  return (
    <div className="Chat-page">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error.status}
        autoHideDuration={6000}
        onClose={() =>
          setError({
            status: false,
            message: null,
          })
        }
      >
        <Alert
          onClose={() =>
            setError({
              status: false,
              message: null,
            })
          }
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error.message}
        </Alert>
      </Snackbar>
      <div className="Chat-main-wrapper">
        <div className="top-nav-chat"></div>
        <div className="toggle-version-div">
          <UserInfo />
          <div
            className="toggle-box active"
            // onClick={toggle}
          >
            <SlEnergy className="icon-1" />
            Gemini 1.0 Pro
            <div className="hover-div">
              <p className="title">Gemini 1.0 Pro</p>
              <p className="text">
                The innovative helmet that combines advanced safety features
                with exceptional comfort and style.
              </p>
            </div>
          </div>

          <div
            className="toggle-box"
            //  onClick={toggle}
          >
            <BsStars className="icon-2" />
            Gemini 1.5 Pro
            <div className="hover-div second">
              <p className="title">Gemini 1.5 Pro</p>
              <p className="text">
                Discover the ultimate conversational AI assistant that combines
                the power of visual, auditory, and textual communication to
                engage users in rich and immersive experiences.
              </p>
            </div>
          </div>
        </div>
        {/* <img
          src={mainLogo}
          alt="logo"
          className="mainlogo"
        /> */}
        <h1>Cortexini</h1>
        <div className="absolute-input-div">
          <div className="absolute-wrapper">
            <div className="suggested-text-div">
              <div
                className="suggested-box"
                onClick={() => handleNewChat("like I'm five years old")}
              >
                <div>
                  <p className="title">Give me ideas</p>
                  <span>like I'm five years old</span>
                </div>
                <BiSolidSend className="send-icon" />
              </div>
              <div
                className="suggested-box"
                onClick={() =>
                  handleNewChat('inviting my neighbors to a barbecue')
                }
              >
                <div>
                  <p className="title">Give me ideas</p>
                  <span>inviting my neighbors to a barbecue</span>
                </div>
                <BiSolidSend className="send-icon" />
              </div>
              <div
                className="suggested-box"
                onClick={() =>
                  handleNewChat(
                    'for a family of 4 to do indoors on a rainy day'
                  )
                }
              >
                <div>
                  <p className="title">Give me ideas</p>
                  <span>for a family of 4 to do indoors on a rainy day</span>
                </div>
                <BiSolidSend className="send-icon" />
              </div>
              <div
                className="suggested-box"
                onClick={() => handleNewChat('in novels and in films')}
              >
                <div>
                  <p className="title">Give me ideas</p>
                  <span>in novels and in films</span>
                </div>
                <BiSolidSend className="send-icon" />
              </div>
            </div>
            <div className="inout-div">
              <textarea
                onChange={(e) => autoResize(e)}
                placeholder="Send a message"
              />
              <button
                className="send-icon"
                disabled={disabled}
                onClick={() => handleNewChat(newMessage)}
              >
                <BiSolidSend />
              </button>
            </div>
            <p className="small-footer-text">
              Free Research Preview. Cortexini 1.0 Pro may produce inaccurate
              information about people, places, or facts. Built on top of Gemini
              by google.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
