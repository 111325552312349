import React, { useContext, useState } from 'react';
import './Settings.scss';
import { AiOutlineClose } from 'react-icons/ai';
import { BsFillDatabaseFill } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DarkModeContext } from '../../context/DarkModeContext';

const Settings = ({ state, setState }) => {
  const [theme, setTheme] = useState('');
  const { dispatch } = useContext(DarkModeContext);

  const handleInputChange = (e) => {
    setTheme(e.target.value);
    const mode = e.target.value;
    dispatch({ type: mode });
    localStorage.setItem('theme', mode);
  };

  const handleTabsChange = (e) => {
    let current = e.target.innerText;
    const optionDiv = document.getElementsByClassName('option');
    const tabdiv = document.getElementsByClassName('tab-details');
    console.log(current);

    const targetDiv = document.getElementById(current);

    for (let i = 0; i < optionDiv.length; i++) {
      optionDiv[i].classList.remove('active');
      tabdiv[i].classList.remove('active');
    }
    targetDiv.classList.add('active');
    e.target.classList.add('active');
  };

  const handleClose = (e) => {
    const target = e.target;
    if (target.classList[0] === 'setting-popup') {
      setState(true);
    }
  };

  return (
    <div
      className={`setting-popup ${state && 'hidden'}`}
      onClick={(e) => handleClose(e)}
    >
      <div className="popup-window">
        <div className="top-window-div">
          <p>Settings</p>
          <button onClick={() => setState(!state)}>
            <AiOutlineClose />
          </button>
        </div>
        <div className="tabs-container">
          <div className="tab-menus">
            <span onClick={handleTabsChange} className="option active">
              <FiSettings className="icon" />
              General
            </span>
            <span onClick={handleTabsChange} className="option">
              <BsFillDatabaseFill className="icon" />
              database
            </span>
          </div>
          <div className="tab-content">
            <div id="General" className="tab-details active">
              <div className="Choice">
                <span>Theme</span>
                <div className="mui-select-wrapper">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Theme</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={theme}
                      label="Theme"
                      onChange={handleInputChange}
                    >
                      <MenuItem value="dark">Dark</MenuItem>
                      <MenuItem value="light">Light</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="Choice">
                <span>Clear all chats</span>

                <button>Clear</button>
              </div>
            </div>

            <div id="database" className="tab-details"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
