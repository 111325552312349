import React, { useEffect, useState } from 'react';
import './Register.scss';
import fullLogo from '../../assets/fulllogo_transparent.png';
import fullLogoDark from '../../assets/fulllogo_transparent_nobuffer.png';
import { Alert, AlertTitle, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { publicRequest } from '../../ApiRequests';

const Register = () => {
  const [current, setCurrent] = useState('');
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [passMismatch, setPassMismatch] = useState(false);
  const [passErrorMsg, setPassErrorMsg] = useState('');
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [error, SetError] = useState(false);
  const [isFetching, SetisFetching] = useState(false);
  const [isSuccessful, setisSuccessful] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    const validateInput = () => {
      if (document.getElementById('main-register').classList[1] === 'next') {
        if (
          data.fName &&
          data.lName &&
          data.password &&
          data.confpassword &&
          data.password === data.confpassword
        ) {
          const password = data.confpassword;
          if (password.length < 8) {
            setPassErrorMsg('password must be at least 8 characters');
          } else {
            setPassMismatch(false);
            setDisabled(false);
          }
        } else {
          if (
            data.fName &&
            data.lName &&
            data.password &&
            data.confpassword &&
            data.password !== data.confpassword
          ) {
            setPassErrorMsg('Password mismatch');
            setPassMismatch(true);
          }
          setDisabled(true);
        }
      } else {
        if (data.email && data.email.length > 0) {
          setEmailError(!isValidEmail(data.email));
          if (!emailError && !show) {
            setDisabled(false);
          } else if (
            !emailError &&
            show &&
            data.username &&
            data.email.length > 0
          ) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        } else {
          setDisabled(true);
        }
      }
    };
    if (data) {
      validateInput();
    }
  }, [data, emailError, passMismatch, show]);

  const handleRegister = async () => {
    await publicRequest.post('/auth/register', {
      email: data.email,
      username: data.username,
      fName: data.fName,
      lName: data.lName,
      password: data.password,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      SetisFetching(true);
      await handleRegister();
      setisSuccessful(true);
      setTimeout(() => {
        navigate('/auth/login');
      }, 4000);
    } catch (err) {
      SetError(true);
    }
  };
  const handleNext = async (e) => {
    e.preventDefault();
    try {
      await handleRegister();
      setUsernameExists(false);
      setEmailExists(false);
      setEmailDisabled(true);
      if (show) {
        setCurrent('next');
        setDisabled(true);
      } else {
        setShow(true);
        setDisabled(true);
      }
    } catch (err) {
      const errMessage = err.response;
      if (errMessage !== undefined) {
        if (errMessage.data === 'email already exists!') {
          // console.clear();
          setEmailExists(true);
        }
        if (errMessage.data === 'username already exists!') {
          // console.clear();
          setUsernameExists(true);
        }
      } else {
        SetError(true);
      }
    }
  };
  return (
    <div className="Register-page">
      {error && (
        <Alert
          severity="error"
          style={{
            width: 'fit-content',
            alignSelf: 'center',
          }}
        >
          <AlertTitle>Error</AlertTitle>
          <strong>Sorry!</strong> something went wrong
        </Alert>
      )}
      <div className="title-logo">
        <p>Cortexini</p>
        {/* <Link to="/">
          <img
            src={fullLogoDark}
            alt="logo"
            className="logo-dark"
          />
        </Link>
        <Link to="/">
          <img
            src={fullLogo}
            alt="logo"
            className="logo-light"
          />
        </Link> */}

        <p>Create your account</p>
        {isSuccessful && (
          <Alert
            severity="success"
            style={{
              width: 'fit-content',
              alignSelf: 'center',
            }}
          >
            <AlertTitle>Success</AlertTitle>
            Your account has been created succesffully
            <br></br>
            <strong>You will be redirected to login page!</strong>
          </Alert>
        )}
      </div>
      <div
        id="main-register"
        className={`register-swiper-mainWrapper ${current}`}
      >
        <div className="register-box">
          <div className="form">
            <div className="input-box">
              <TextField
                onChange={handleChange}
                type="email"
                id="email"
                label="E-mail"
                inputProps={{ inputMode: 'email' }}
                error={emailError || emailExists}
                disabled={emailDisabled}
                helperText={`${
                  emailError
                    ? 'Please provide valid email'
                    : emailExists
                    ? 'Email is already exists!'
                    : ''
                }`}
              />
              <TextField
                onChange={handleChange}
                className={`userInput ${show && 'show'}`}
                type="text"
                id="username"
                label="Username"
                error={usernameExists}
                helperText={`${
                  usernameExists ? 'username already exists!' : ''
                }`}
              />
            </div>
            <button
              disabled={disabled}
              className="next-register-bttn"
              onClick={handleNext}
            >
              Continue
            </button>
            <div className="registerOrsign-div">
              <span>Already have an account?</span>
              <Link to="/login">Sign in</Link>
            </div>
          </div>
        </div>
        <div className="register-box">
          <div className={`form row-${current}`}>
            <div className="input-box row">
              <TextField
                id="fName"
                onChange={handleChange}
                label="First Name"
              />
              <TextField id="lName" onChange={handleChange} label="Last Name" />
            </div>
            <div className="input-box">
              <TextField
                id="password"
                onChange={handleChange}
                type="password"
                label="Password"
                error={passMismatch}
                helperText={`${passMismatch ? passErrorMsg : ''}`}
              />
              <TextField
                onChange={handleChange}
                id="confpassword"
                type="password"
                label="confirm Password"
                error={passMismatch}
                helperText={`${passMismatch ? passErrorMsg : ''}`}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={disabled || isFetching}
              className="next-register-bttn"
            >
              Register
            </button>
            <div className="registerOrsign-div">
              <span>Already have an account?</span>
              <Link to="/login">Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
