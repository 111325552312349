import Cookies from 'js-cookie';
import { publicRequest } from '../ApiRequests';
import { loginFailure, loginStart, loginSuccess } from './userSlice';

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const response = await publicRequest.post('/auth/login', user);
    const token = response.data.token;
    Cookies.set('Authorization', token, {
      domain: 'menamahany.tech',
      sameSite: 'none',
      secure: true,
    });
    const data = response.data.userData;
    const { password, ...userData } = data;
    dispatch(loginSuccess(userData));
  } catch (err) {
    if (err.message === 'Network Error') {
      dispatch(loginFailure(err.message));
    } else {
      dispatch(loginFailure(err.response.data));
    }
  }
};
