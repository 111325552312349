import React, { useEffect, useState } from 'react';
import './MiniHome.scss';
import { Link } from 'react-router-dom';
// import fullLogo from '../../assets/fulllogo_transparent.png';
import Typewriter from 'typewriter-effect';

const MiniHome = () => {
  const [page, setPage] = useState(0);
  const type = document.getElementsByClassName('title');

  useEffect(() => {
    if (page > 0 && type.length > 0) {
      handleNext();
    }
  }, [page]);

  const handleNext = () => {
    if (page < type.length) {
      type[page - 1].classList.remove('visible');
      type[page].classList.add('visible');
    } else {
      type[page - 1].classList.remove('visible');
      type[0].classList.add('visible');
    }
  };

  return (
    <div className="miniHome">
      <div className="left-side">
        <div className="brand-identity">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              height: '35px',
            }}
          >
            <span className="brand-name">Cortexini</span>
            <span className="circle"></span>
          </div>
        </div>
        <div className="type-animation-div">
          <div className="type-wrapper">
            <div className="title-div">
              <p className="title visible">Give me ideas</p>
              <p className="title">Education and Learning</p>
            </div>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .pauseFor(2000)
                  .typeString(
                    'Start a daily or weekly art journal to document your creative journey'
                  )
                  .pauseFor(2000)
                  .deleteAll({ speed: 40 })
                  .start()
                  .typeString(
                    'Take up a new hobby or skill, like cooking, coding, or playing a musical instrument'
                  )
                  .pauseFor(2000)
                  .deleteAll({ speed: 40 })
                  .start()
                  .typeString('Read a book on a topic you know little about.')
                  .pauseFor(2000)
                  .deleteAll({ speed: 40 })
                  .callFunction(() => {
                    setPage(1);
                  })
                  .start()
                  .pauseFor(2000)
                  .typeString(
                    'Take an online course or enroll in a class at a local college'
                  )
                  .pauseFor(2000)
                  .deleteAll({ speed: 40 })
                  .start()
                  .typeString(
                    'Attend workshops or seminars on topics of interest'
                  )
                  .pauseFor(2000)
                  .deleteAll({ speed: 40 })
                  .start()
                  .typeString(
                    'Join a book club to engage in intellectual discussions'
                  )
                  .pauseFor(2000)
                  .deleteAll({ speed: 40 })
                  .callFunction(() => {
                    setPage(2);
                  })
                  .start();
              }}
              options={{
                loop: true,
                cursor: '🟠',
                delay: 10,
              }}
            />
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="cto-div">
          <h1>Get started</h1>
          <div className="bttn-div">
            <Link to="/auth/login">Login</Link>
            <Link to="/auth/register">Sign up</Link>
          </div>
        </div>
        <div className="footer-div">
          <p>Cortexini</p>
          {/* <img src={fullLogo} alt="/" /> */}
          <div className="links-div">
            <Link to={'/'}>Terms of use</Link>
            <span></span>
            <Link to={'/'}>Privacy policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniHome;
